import Header from "../components/Header"
import Footer from "../components/Footer"
import Breadcrumb from "../components/Breadcrumb"
const Curriculum = () => {
    return (
        <>
            <Header />
            <Breadcrumb mid="About" breadcrumbName="Curriculum" />

            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title">
                                <div>
                                    <h1><span>Curriculum </span></h1>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-12">
                            <p>The school follows the curriculum laid by the Council for the Indian School Certificate Examination, New Delhi.</p>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <Footer />
                            </>
                            )
}
                            export default Curriculum