import { useEffect, useState } from 'react'; 
 
import Header from "../components/Header";
import Footer from "../components/Footer"; 
import Breadcrumb from "../components/Breadcrumb";
import {getPrincipalMessage} from'../Service/Api'
const PrincipalMessage = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'Principal Message - Tagore Public School';  
      }, []);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
    console.log(data);
 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const principalData = await getPrincipalMessage();
                setData(principalData);
                
            } catch (error) {
                console.log("Data Error", error);
            } finally{
                setLoading(false)
            }
        };
        fetchData();
    }, []);
    const emptyData = [
       { id: 1}
   ];
  return (
     <>
       <Header />
            <Breadcrumb mid="About" breadcrumbName="Principal's Message" />
                <div className="innerSection">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className='title'>
                        <h1><span>Principal's</span> Message</h1>
                        </div> 
                    </div>
                </div>
                
                {data?.length > 0 ? data?.filter((div) => div.category === "Principal").map((item, index) => ( 
                    <div className="clearfix" key={index}>
                     <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`} className="img-fluid col-md-4 float-md-end mb-3 msgimg"  alt="St mary school mirzapur" />
                
                       <p><div dangerouslySetInnerHTML={{ __html: item.message }} /></p> 
                      <p className="name"> <div dangerouslySetInnerHTML={{ __html: item.name }} /><span> <div dangerouslySetInnerHTML={{ __html: item.category }} /> </span></p>
              
                 
                </div> 
                )) : emptyData.map((item) => (
                    <div className="clearfix"> 
                    <div class="image_blk float-md-start msgimg">
                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/principal-message.jpg" className="img-fluid mb-3 " alt="St Mary School, Mirzapur"/>
                    <h5>Rev. Fr. Jacob Bona D' Souza</h5>
                            <p>Principal</p>
                    </div>
                    <p><b>My Dear Students, Parents, and well-wishers, </b>
                    </p>
                    <p>
                    Greetings to all of you. I am happy to be here at St. Mary School as the Principal of this premier education institution in Mirzapur. I am sure with the cooperation of our parents, students, and all the stakeholders we can scale the new heights in molding the young minds who are given to our care. It gives me immense joy to pen down these few lines to you through the school, very specially, in thee days, the details of regular online activities. I wish that you browse through the pages of our websites, atleast once in a week to get the necessary information and updates.</p>
                        
                    </div> 
                    ))}
             </div>
             
        </div> 
       <Footer/> 
     </>
  )
}

export default PrincipalMessage
