import React from "react"; 
import Header from "../components/Header";
import Footer from "../components/Footer"; 
import Breadcrumb from "../components/Breadcrumb";
const About = () => {

  return (
    <>
      <Header />
      <Breadcrumb mid="About" breadcrumbName="About Us" />

      <div className="innerSection">
        <div className="container">         
          <div className="row">
            <div className="col-md-12">
            <div className="title">
                <div>
                    <h1><span>About  </span> Us</h1>
                </div>
            </div>
               
            </div>
            <div className="col-md-12">
                <p><span>St. Mary′s School</span>  was born on 10th July 1989, is a minority institution and administered by the Roman Catholic Diocese of Allahabad.The school is dedicated to our blessed Mother Mary, Mother of Lord Jesus Christ. Fr. Sebastian D′cruz began the construction and started the school with 4 classrooms with just 175 students and 6 teachers. Fr. Dennis D′souza was the Diocesan Education Officer in those days.</p>
                <p><span>St. Mary′s School, Mirzapur, </span> aims at creating individuals who are intellectually competent, morally sound, psychologically whole, imbued with the sense of the divine, committed to the case of justice, love and peace, and ever open to further growth. It aspires towards creating a humane society free from prejudice, superstitions and discrimination based on sex, religion, caste, creed and economic status. It strives to inculcate respect for the dignity of the human person and concern for each other, especially the under privileged in the society. To make this mission a reality it expects parents, guardians, teachers and well-wishers to share this mission in spirit with the school management and co-operate wholeheartedly in all this endeavors.   
                <a href="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/school-details.jpg" target="_blank">More..</a></p>

                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/our-school.jpg"  className="img-fluid abt-sch" alt="St mary school mirzapur" />
            </div>
          </div>
        </div>
      </div>
      <Footer /> 
    </>
  )
}

export default About