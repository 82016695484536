import Header from "../components/Header"
import Footer from "../components/Footer"
import Breadcrumb from "../components/Breadcrumb"
const RoleOfHonour  = () => {
    return (
        <>
            <Header />
            <Breadcrumb mid="About" breadcrumbName="Role Of Honour" />

            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title">
                                <div>
                                    <h1><span>Role Of Honour </span></h1>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-12">
                           <p>The school honours students who are exemplary in academics. The first, second and third rank holders are given certificates and prizes.The students who have 100% attendance are also given prizes. The best three rank holders in ICSE Class X and ISC Class XII are given cash prizes with mementos on the annual day every year.</p>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <Footer />
                            </>
                            )
}
                            export default RoleOfHonour