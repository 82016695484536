import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { getEvents } from '../Service/Api';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import '../../node_modules/slick-carousel/slick/slick.css';
import '../Assets/css/slick.css'
import '../Assets/css/slick-theme.css'
// import '../../node_modules/slick-carousel/slick/slick-theme.css';

const Events = () => {
    const [data, setData] = useState([]);
    
    useEffect(() => {
      const getData = async () => {
        const datas = await getEvents();
        setData(datas);
      };
      getData();
    }, []);
    
    const settings = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            { breakpoint: 1024, settings: { slidesToShow: 3 } },
            { breakpoint: 768, settings: { slidesToShow: 2 } },
            { breakpoint: 480, settings: { slidesToShow: 1 } }
        ]
    };

    return (
        <Slider {...settings}>
            {data?.length > 0 ? (
                data.map((item, index) => (
                    <div className="item" key={index}>
                        <div className='evt-blk'>
                            <div className="evt-img">
                                {item.images.length > 0 ? 
                                    (<img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.images}`} />) : 
                                    (<img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/event.jpg" />)}
                                <div className="evt-title"><h3>{item.title}</h3></div> 
                            </div>
                            <div className='evt-content'> 
                                <p><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/calender.png" />
                                    {new Date(item.date).toLocaleString('en-US', { month: 'short' })}  
                                    {new Date(item.date).getDate()}, {new Date(item.date).getFullYear()} 
                                    <span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/clock.png" />{item.time}</span>
                                </p>
                                <p><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/location.png" />{item.venue}</p>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div className="item">
                    <div className='evt-blk'>
                        <div className="evt-img">
                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/event.jpg" />
                            <div className="evt-title"><h3>Event Title</h3></div> 
                        </div>
                        <div className='evt-content'> 
                            <p><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/calender.png" />DD. MM.YYYY 
                                <span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/clock.png"></img>HH:MM</span>
                            </p>
                            <p><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/location.png" />School</p>
                        </div>
                    </div>
                </div>
            )}
        </Slider>
    );
};

export default Events;
