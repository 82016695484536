import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import { Link } from 'react-router-dom'
const SchoolTopper = () => {

    return (
        <>
            <Header />
            <Breadcrumb mid="Results" breadcrumbName="School Toppers" />

            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title">
                                <div>
                                    <h1>School Toppers</h1>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-6">
                            <Link to="/topperXII">
                                <div className="topper-pg">
                                    <h3>ICSE Toppers</h3>
                                    <i class="bi bi-mortarboard"></i>
                                    <p>View Toppers</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-6">
                            <Link to="/topperX">
                                <div className="topper-pg">
                                    <h3>ISC Toppers</h3>
                                    <i class="bi bi-mortarboard"></i>
                                    <p>View Toppers</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title">
                                <div>
                                    <h2>Result Analysis</h2>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/pdf/Class_X_St_Mary_Results_2020.jpg">
                                <div className="topper-pg">
                                    <h3>ICSE X</h3> 
                                    <p>VieW Toppers</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-4">
                            <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/pdf/Class_XII_Science_St_Mary_Results_2020.jpg">
                                <div className="topper-pg">
                                    <h3>ISC Science</h3> 
                                    <p>VieW Toppers</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-4">
                            <Link to="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/pdf/Class_XII_Commerce_St_Mary_Results_2020.jpg">
                                <div className="topper-pg">
                                    <h3>ISC Commerce</h3> 
                                    <p>VieW Toppers</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default SchoolTopper