import Header from "../components/Header"
import Footer from "../components/Footer"
import Breadcrumb from "../components/Breadcrumb"
const RulesRegulations = () => {
    return (
        <>
            <Header />
            <Breadcrumb mid="About" breadcrumbName="Rules & Regulations" />

            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title">
                                <div>
                                    <h1><span>Rules &   </span> Regulations</h1>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-12">
                            <ul className="listView">
                                <li>All students should be present in school on the opening day of each term.</li>
                                <li>If a student has been absent from the school, he / she will be allowed to attend the class only after the Principal signs the leave application and permits the student to attend the class. Students are directed to use the absence and leave record in the School Diary.</li>
                                <li>Irregular attendance, unjustified absence or objectionable moral behaviour justifies dismissal. If a student is absent for ten consecutive days without an application, or for three days at the beginning of a term, his / her name may be struck off the rolls.</li>
                                <li>No student who has been absent from school or is late will be admitted in the class without the written permission of the Principal.</li>
                                <li>All students are expected to line up in silence for the assembly at the sound of the first bell. After the assembly they should march in silence to their classes.</li>
                                <li>No books, other than the required text books, may be brought to school.</li>
                                <li>No students should leave the school premises during class hours without the written permission of the Principal.</li>
                                <li>Any damage to school property will have to be made good. The decision of the Principal regarding the amount is final.</li>
                                <li>The school is not responsible for goods lost. Students should not carry or wear valuable articles when coming to school.</li>
                                <li>No student suffering from a contagious or infectious disease will be permitted to attend school.</li>
                                <li>After recovery a medical certificate is to be submitted.</li>
                                <li>During school hours, students are not allowed to receive visitors or make phone calls or to answer a phone call. If the matter is very urgent, the message will be taken or given.</li>
                                <li>Any student desiring to be exempted form P.T. games must produce a doctor´s certificate to the effect that he / she is physically unfit to take part along with the application from the parent / guardian.</li>
                                <li>All students should be habitually clean and always neatly dressed. They are expected to come in the school uniform everyday.</li>
                                <li>Students must come to class with the lessons well prepared and the home work completed.</li>
                                <li>The School Diary should be brought daily to school. A student who doesn´t bring his / her school diary may not be allowed to enter his / her class.</li>
                                <li>Parents/Guardians may be called to explain the matter to the School Authorities, if the student does not bring the diary for 3 days.</li>
                                <li>Running, playing and shouting inside the school building is never allowed.</li>
                                <li>When students move along the verandahs, to other class rooms, they must walk in silence and in a single file. They should always keep to the left as far as possible.</li>
                                <li>Students should be particularly careful not to throw paper or fruit peels anywhere in the school premises. They should use the bins especially provided for that purpose.</li>
                                <li>No student should remain in the classroom during the interval, P.T. or Games period unless permitted by the Principal.</li>
                                <li>Students are responsible to the school authorities not only for their conduct in school but also for their general behaviour outside. Any reported or observed objectionable conduct out of the school shall make them liable to disciplinary action.</li>
                                <li>A student who takes or gives dishonest help during examinations will be debarred from the rest of the examination and such absence will not be condoned. Such a student may be dismissed from the school.</li>
                                <li>Students are not allowed to enter a classroom other than their own.</li>
                                <li>Articles found in school or within its premises are to be handed over to the office.</li>
                                <li>Parents send their children to this school so that they may be proficient in both English and Hindi.</li>
                                <li>In the school premises all are expected to speak only in English.</li>
                                <li>Students should at all cost avoid writing , carving and scribbling on walls or furniture. Such habits are detrimental to good education and a sign of low taste and may invite a fine or other punishment.</li>


                                <li><a href="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/pdf/fee-structure.pdf" target="_blank">Fee Structure</a></li>


                                <li>The Principal´s written permission is required:<br />
                                    –  to make a collection for any purpose whatever;<br />
                                        –  to arrange for a party, picnic or meeting;<br />
                                            –  to join a sports meet or to play a game not arranged by the school.<br />
                                                –  to give present to teachers or stage demonstrations in their honour;<br />
                                                    –  to arrange for private tuition from the school teachers.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <Footer />
                            </>
                            )
}
                            export default RulesRegulations