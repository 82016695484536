import React from "react"; 
import Header from "../components/Header";
import Footer from "../components/Footer"; 
import Breadcrumb from "../components/Breadcrumb";
const FormerFaculty = () => {

  return (
    <>
      <Header />
      <Breadcrumb mid="Academics" breadcrumbName="Former Faculty" />

      <div className="innerSection">
        <div className="container">         
          <div className="row">
            <div className="col-md-12">
            <div className="title">
                <div>
                    <h1><span>Former </span> Faculty </h1>
                </div>
            </div>
               
            </div>
            </div>
            <div className="row">
     
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div class="m-com">
                <img  alt="St mary school mirzapur"  src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/Gregory-Dzousa.jpg"  />
                <h3>Rev. Fr. Gregory D’zousa</h3>
                <p>FORMER PRINCIPAL</p>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div class="m-com">
                <img  alt="St mary school mirzapur"  src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/Sneha-Gupta.jpg" />
                <h3>Sneha Gupta</h3>
                <p>ASST. TEACHER</p>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div class="m-com">
                <img  alt="St mary school mirzapur"  src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/PREMLATA-AGRAWAL.jpg"/>
                <h3>MRS. PREMLATA AGRAWAL</h3>
                <p>SR. LECTURER</p>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div class="m-com">
                <img  alt="St mary school mirzapur"  src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/James-Francis.jpg" />
                <h3>Rev. Fr. James Francis</h3>
                <p>DIOCESAN EDUCATION SECRETARY DEO</p>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div class="m-com">
                <img  alt="St mary school mirzapur"  src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/Sr-Pramila.jpg" />
                <h3>Sr. Pramila</h3>
                <p>ASSISTANT TEACHER</p>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div class="m-com">
                <img  alt="St mary school mirzapur"  src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/Kanchan-Gupta.jpg" />
                <h3>Kanchan Gupta</h3>
                <p>ASSISTANT TEACHER</p>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div class="m-com">
                <img  alt="St mary school mirzapur"  src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/Sr-Veronica.jpg" />
                <h3>Sr.Veronica</h3>
                <p>ASSISTANT TEACHER</p>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div class="m-com">
                <img  alt="St mary school mirzapur"  src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/Miss-Dipti.jpg" />
                <h3>Late Miss Dipti Joab</h3>
                <p>ASSISTANT TEACHER</p>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div class="m-com">
                <img  alt="St mary school mirzapur"  src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/Neha-Gupta.jpg" />
                <h3>Neha Gupta</h3>
                <p>ASSISTANT TEACHER</p>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div class="m-com">
                <img  alt="St mary school mirzapur"  src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/pooja.jpg" />
                <h3>Pooja Rathore</h3>
                <p>ASSISTANT TEACHER</p>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div class="m-com">
                <img  alt="St mary school mirzapur"  src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/priti-gaur.jpg" />
                <h3>Priti Gaur</h3>
                <p>ASSISTANT TEACHER</p>
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <div class="m-com">
                <img  alt="St mary school mirzapur"  src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/ASHIHRII.jpg" />
                <h3>MR. ASHIHRII</h3>
                <p>ASST. TEACHER</p>
            </div>
        </div>
    </div>
             
        </div>
      </div>
      <Footer /> 
    </>
  )
}

export default FormerFaculty