import Header from "../components/Header"
import Footer from "../components/Footer"
import Breadcrumb from "../components/Breadcrumb"
const CampusTour = () => {
    return (
        <>
            <Header />
            <Breadcrumb mid="Facilities" breadcrumbName="Campus Tour" />

            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title">
                                <div>
                                    <h1><span>Campus  </span>Tour</h1>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-12">
                            <p>The School undertakes educational tours to students from Class VI to XII. This year the students of Class XII went on and educational tour to Kolkata for 3 days.</p>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <Footer />
                            </>
                            )
}
                            export default CampusTour