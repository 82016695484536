import React from "react"; 
import Header from "../components/Header";
import Footer from "../components/Footer"; 
import Breadcrumb from "../components/Breadcrumb";
const HistoryAim = () => {
    return (
        <>
            <Header />
            <Breadcrumb mid="About" breadcrumbName="History, Aim, Vision & Mission" />

            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title">
                                <div>
                                    <h1><span>Our </span>History</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                           <p>St. Mary´s School was born on 10th July 1989, is a minority institution and administered by the Roman Catholic Diocese of Allahabad. The school is dedicated to our blessed Mother Mary, Mother of Lord Jesus Christ. Fr. Sebastian D´cruz began the construction and stated the school with 4 classrooms with just 175 students and 6 teachers. Fr. Dennis D´souza was the Diocesan Education Officer in those days.</p>
                        </div>
                        <div className="col-md-12">
                            <div className="title">
                                <div>
                                    <h1><span>The Aim of  </span>S.M.S</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                           <p>Every child is a gift of God. Our commitment is to have integral education, the harmonious loving of its society. To appreciate the world and to live in it as a global citizen.</p>
                           <p>We aim at moulding our students with 5C’s to become a FINE human being :-</p>
                           <ul className="listView">
                            <li>CHARACTER</li>
                            <li>COMPASSION</li>
                            <li>CONSCIENCE</li>
                            <li>COMMITMENT</li>
                            <li>COMPETENCE</li>
                           </ul>
                        </div>
                        <div className="col-md-12">
                            <div className="title">
                                <div>
                                    <h1><span>Our </span>Vision</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                           <p><span>St. Mary´s School</span> stands for academic excellence, development of skills and character formation based on the love of God and the service of man as modeled in Jesus Christ. It exist primarily for the education of Catholic children but extends its service to the development of others irrespective of caste, creed and religion. English is the medium of instruction, communication and examination. The school is affiliated to the C.I.S.C.E. , New Delhi, India</p>
                        </div>
                        <div className="col-md-12">
                            <div className="title">
                                <div>
                                    <h1><span>Our </span>Mission</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                           <p><span>St.Mary’s School, Mirzapur,</span> aims at creating individuals who are intellectually competent, morally sound, psychologically whole, imbued with the sense of the divine, committed to the cause of justice, love and peace, and ever open to further growth. It aspires towards creating a humane society free from prejudices, superstitions and discrimination based on sex, religion, caste creed and economic status. It strives to inculcate respect for the dignity of the human person and concern for each other, especially the underprivileged in the society. To make this mission a reality it expects parents, guardians, teachers and well-wishers to share this mission in spirit with the school management and co-operate wholeheartedly in all its endeavors.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )

}
export default HistoryAim