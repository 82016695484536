import React from 'react'; 
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const Events = () => {
    var settings = {
        loop: true,
        margin: 10,
        responsiveClass: true,
        autoplay: true,
        dots: false,
        items: 1,
        responsive: {
            0: {
                items: 1,
            },
            567: {
                items: 1,
            },
            768: {
                items: 1,
            },
            992: {
                items: 1,
            },
            1200: {
                items: 1,
            },
            1600: {
                items: 1,
            }
        }
    };

    return (
        <OwlCarousel className='owl-theme' loop margin={10} {...settings}>
          
            <div className="item">
              <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/sld_1.jpg' alt="St Mary school, Mirzapur" />
            </div>
            <div className="item">
              <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/sld_2.jpg' alt="St Mary school, Mirzapur" />
            </div>
            <div className="item">
              <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/sld_3.jpg'  alt="St Mary school, Mirzapur"/>
            </div>
            <div className="item">
              <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/sld_4.jpg' alt="St Mary school, Mirzapur" />
            </div>
            {/* <div className="item">
              <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/sld_5.jpg' alt="St Mary school, Mirzapur" />
            </div> */}
            <div className="item">
              <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/sld_6.jpg' alt="St Mary school, Mirzapur" />
            </div>
            <div className="item">
              <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/sld_7.jpg' alt="St Mary school, Mirzapur" />
            </div>
            {/* <div className="item">
              <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/sld_8.jpg' alt="St Mary school, Mirzapur" />
            </div> */}
            {/* <div className="item">
              <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/sld_9.jpg' alt="St Mary school, Mirzapur" />
            </div> */}
            <div className="item">
              <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/sld_10.jpg' alt="St Mary school, Mirzapur" />
            </div>
            <div className="item">
              <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/sld_11.jpg'  alt="St Mary school, Mirzapur"/>
            </div>
            {/* <div className="item">
              <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/sld_12.jpg' alt="St Mary school, Mirzapur" />
            </div> */}
            <div className="item">
              <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/sld_13.jpg' alt="St Mary school, Mirzapur" />
            </div>
            <div className="item">
              <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/sld_14.jpg' alt="St Mary school, Mirzapur" />
            </div>
            <div className="item">
              <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/sld_15.jpg' alt="St Mary school, Mirzapur" />
            </div>
          
        </OwlCarousel>
    );
};

export default Events;
