import React, { useState } from 'react';

const Tab = ({ onClick, isActive, year, images }) => {
    return (
        <div onClick={onClick} className={`tabs-block ${isActive ? 'active' : ''}`}>
            <img src={images.static} />
            <img src={images.animated} className='faci-gif' />
        </div>
    );
};

const Facilities = () => {
    const [activeTab1, setActiveTab1] = useState('tab11'); 

    const tabs = [
        { id: 'tab11', year: '2023-2024', images: { static: 'https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/campus.png', animated: 'https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/campus.gif' }, content: 'Camps  & Tours', description: 'The School undertakes educational tours to students from Class VI to XII. This year the students of Class XII went on and educational tour to Kolkata for 3 days.' },
        { id: 'tab22', year: '2022-2023', images: { static: 'https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/lab.png', animated: 'https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/lab.gif' }, content: 'Laboratories', description: 'The school has an up – to – date Lab Facility for the students. We have 4 Computer Labs, with online ups for power backups, and One Physics, Chemistry and Biology Lab to help the students in their practical work.' },
        { id: 'tab33', year: '2021-2022', images: { static: 'https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/book.png', animated: 'https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/book.gif' }, content: 'Library', description: 'The school has a well furnished library and caters to the need of the students with regard to literary books as well as books related to academics. The teachers also get reference books in the respective subjects.' },
       
    ];
    const tabs1 = [
        
        { id: 'tab44', year: '2020-2021', images: { static: 'https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/sport.png', animated: 'https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/sport.gif' }, content: 'Sports', description: 'Sports and games is encouraged in our school. Games like Cricket, Football, Throw ball, Basketball ball, Volleyball and Badminton are played by the students. We have 3 games teachers, two male and one female to train the students in the various disciplines. We also have the Annual Athletic and Sports Day each year. The competitions are held house wise.' },
        { id: 'tab55', year: '2019-2020', images: { static: 'https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/stage.png', animated: 'https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/stage.gif' }, content: 'Summer Camp', description: '' },
        
    ];

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='mid-block'>
                        
                    {tabs.map((tab) => (
                        <div className='tab-view'>
                            <Tab
                                key={tab.id}
                                year={tab.year}
                                images={tab.images}
                                isActive={activeTab1 === tab.id}
                                onClick={() => setActiveTab1(tab.id)}
                            />
                            </div>
                        ))}
                        </div>
                    <div className='mid-block align-item-top'>
                    {tabs1.map((tab) => (
                        <div className='tab-view'>
                            <Tab
                                key={tab.id}
                                year={tab.year}
                                images={tab.images}
                                isActive={activeTab1 === tab.id}
                                onClick={() => setActiveTab1(tab.id)}
                            />
                            </div>
                        ))}
                       
                    </div>
                </div>

                <div className='col-md-6 change-title'>
                    {tabs.map((tab) => (
                        activeTab1 === tab.id && (
                            <div key={tab.id}>
                                <h3>{tab.content}</h3>
                                <p>{tab.description}</p>
                            </div>
                        )
                    ))}
                    {tabs1.map((tab) => (
                        activeTab1 === tab.id && (
                            <div key={tab.id}>
                                <h3>{tab.content}</h3>
                                <p>{tab.description}</p>
                            </div>
                        )
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Facilities;
