import Header from "../components/Header"
import Footer from "../components/Footer"
import Breadcrumb from "../components/Breadcrumb"
const Library = () => {
    return (
        <>
            <Header />
            <Breadcrumb mid="Facilities" breadcrumbName="Library" />

            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title">
                                <div>
                                    <h1>Library</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <p>The school has a well furnished library and caters to the need of the students with regard to literary books as well as books related to academics. The teachers also get reference books in the respective subjects.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                            <div class="labs-box">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/Library.jpg"  alt="St Mary School, Mirzapur" className="img-fluid" />
                                <h3>Bio Lab</h3>
                            </div>
                        </div>
                   
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Library