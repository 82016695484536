import Header from "../components/Header"
import Footer from "../components/Footer"
import Breadcrumb from "../components/Breadcrumb"
const SchoolUniform = () => {
    return (
        <>
            <Header />
            <Breadcrumb mid="About" breadcrumbName="School Uniform" />

            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title">
                                <div>
                                    <h1><span>School  </span> Uniform</h1>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-12">  
      <p>Parents are requested to send the children to school tidily dressed, wearing the school uniform. If the children fail to wear the school uniform, they will not be allowed to enter their class. The prescribed uniform is as follows :</p>
      <h3>Summer Uniform</h3>
      <p><strong>Monday to Friday — Boys:</strong> White shirts, Navy Blue pants.<br />
        <strong>K.G. to Class IV  Girls:</strong> White Short sleeved blouses, Navy blue skirt.<br />
        <strong>V to Class VII  Girls:</strong> White short sleeved blouses, Navy blue divider skirt.<br />
        <strong>VII to Class XII  Girls:</strong> Navy blue suit, white salwar with dupatta.<br />
        <strong>except VIII to XII Girls All :</strong> School tie &amp; belt.<br />
        <strong>All : </strong> Black shoes and navy blue socks.<br />
        <strong>Saturday &amp; Sports :</strong> Boys: White shirts, White pants.<br />
        <strong>K.G. to Class IV Girls : </strong>House T-Shirts, White Skirt<br />
        <strong>V to Class XII Girls :</strong> House T-Shirts, White divided skirt.<br />
        <strong>All :</strong> White shoes and white socks.
        </p>
        <h3>Winter Uniform</h3>
        <p><strong>Boys:</strong> School dress, Navy blue pullover with V-shaped neck &amp; Blue Blazer with School monogram.</p>
        <p><strong>Girls:</strong> School dress, Navy blue pullover with V-shaped neck &amp; Blue Blazer with School monogram.</p>
 
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default SchoolUniform