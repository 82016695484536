import React from 'react' 
import {Link} from 'react-router-dom'
import Header from "../components/Header";
import Footer from "../components/Footer"; 
import Breadcrumb from "../components/Breadcrumb";
const ContactUs = () => {
    return (
        <>
            <Header />
         
            <Breadcrumb mid="Contact" breadcrumbName="Contact Us" />
            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="title">
                                <div>
                                    <h3><span>Contact </span> Us</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>    
                        <div className='col-lg-12'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.027871809532!2d82.57586871498955!3d25.13474854039938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x398fc030b74246c7%3A0x6e2d40ad348ba6ff!2sSt.%20Mary's%20School%20%26%20College!5e0!3m2!1sen!2sin!4v1606479228221!5m2!1sen!2sin" width="100%" height="350" style={{ border: " 0;" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>                    
                        <div className='col-lg-12'>
                            <div className="contact-container">
                                <div className="contact-blk">
                                    <div className="contact-icon">
                                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/location1.png" className="img-fluid" alt="St mary school mirzapur"  />
                                    </div>
                                    <div className="contact-info">
                                        <h6 className="title">Address</h6>
                                        <p><Link to="https://maps.app.goo.gl/3aEy9QW6euYGx1cbA" target="_blank">
                                        Station Road, Pilikothi, Mirzapur, Pin Code: 231001, Uttar Pradesh, INDIA</Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="contact-blk">
                                    <div className="contact-icon">
                                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/phone1.png" className="img-fluid" alt="St mary school mirzapur"  />
                                    </div>
                                    <div className="contact-info">
                                        <h6 className="title">Phones</h6>
                                        <p>05442 350050, 05442 350051, 05442 350052 </p>
                                    </div>
                                </div>
                                <div className="contact-blk">
                                    <div className="contact-icon">
                                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/mail1.png" className="img-fluid" alt="St mary school mirzapur"  />
                                    </div>
                                    <div className="contact-info">
                                        <h6 className="title">Email</h6>
                                        <p><Link to="mailto: info@stmarymirzapur.com"> info@stmarymirzapur.com</Link> <Link to="mailto:stmarymzp1@rediffmail.com" >stmarymzp1@rediffmail.com</Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>




                </div>
            </div>
            <Footer />
        </>
    )
}

export default ContactUs
