import Header from "../components/Header"
import Footer from "../components/Footer"
import Breadcrumb from "../components/Breadcrumb"
const Laboratory = () => {
    return (
        <>
            <Header />
            <Breadcrumb mid="Facilities" breadcrumbName="Laboratory" />

            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title">
                                <div>
                                    <h1>Laboratory</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <p>The school has an up – to – date Lab Facility for the students. We have 4 Computer Labs, with online ups for power backups, and One Physics, Chemistry and Biology Lab to help the students in their practical work.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                            <div class="labs-box">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/Bio-Lab.jpg"  alt="St Mary School, Mirzapur" className="img-fluid" />
                                <h3>Bio Lab</h3>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                            <div class="labs-box">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/ChemistryLab.jpg"  alt="St Mary School, Mirzapur" className="img-fluid" />
                                <h3>Chemistry Lab</h3>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                            <div class="labs-box">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/Physics-Lab.jpg"  alt="St Mary School, Mirzapur" className="img-fluid" />
                                <h3>Physics Lab</h3>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                            <div class="labs-box">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/Computer-lab-1.jpg" alt="St Mary School, Mirzapur" className="img-fluid" />
                                <h3>St. Jame's Computer Lab</h3>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                            <div class="labs-box">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/Computer-lab-2.jpg" alt="St Mary School, Mirzapur" className="img-fluid" />
                                <h3>St. John's Computer Lab</h3>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                            <div class="labs-box">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/Computer-lab-3.jpg" alt="St Mary School, Mirzapur" className="img-fluid" />
                                <h3>St. Peter's Computer Lab 1</h3>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                            <div class="labs-box">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/Computer-lab-4.jpg" alt="St Mary School, Mirzapur" className="img-fluid" />
                                <h3>St. Peter's Computer Lab 2</h3>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                            <div class="labs-box">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/Computer-lab-5.jpg" alt="St Mary School, Mirzapur" className="img-fluid" />
                                <h3>St. Francis' Computer Lab</h3>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                            <div class="labs-box">
                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SMSMU/public/Images/Computer-lab-6.jpg" alt="St Mary School, Mirzapur" className="img-fluid" />
                                <h3>St. Francis' Computer Lab</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Laboratory