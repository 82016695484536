import Header from "../components/Header"
import Footer from "../components/Footer"
import Breadcrumb from "../components/Breadcrumb"
const AdmissionProcedure = () => {
    return (
        <>
            <Header />
            <Breadcrumb mid="About" breadcrumbName="Rules & Regulations" />

            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title">
                                <div>
                                    <h1><span>Admission  </span> Procedure</h1>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-12">  
      <p><b>Forms for admission is distuributed in the first week of January from the school office.</b></p>
      <ul class="listView">
        <li>Students joining the school for the first time must produce a satisfactory evidence of the date of birth such as an extract from the municipal or village panchayat or hospital records or a Baptismal Certificate. Three plus is the age for admission to LKG A corrresponsing scale of age is fixed for subsequent classes.</li>
        <li>When a student is admitted on a transfer certificate he / she shall not be placed in any class higher than that for which the certificate shows him / her to be qualified, nor will he / she be promoted before the end of a school year.</li>
        <li>A student who does not complete the school calendar year is not eligible for promotion; hence admission will be made accordingly.</li>
        <li>No student will be admitted without a transfer certificate from the school last attend to the class pending formal admission.</li>
      </ul>

      <h3>Entrance Exam</h3>
      <p><b>LKG </b>— Interaction with parents / student in the school.<br />
          <b>UKG and above</b> — written examination.
      </p>
      <h3>Scholarships</h3>
      <p>Meritorious students who have appeared in the board examination are awarded in the School´s Annual Function ever year and given a fee concession.</p>
    
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <Footer />
                            </>
                            )
}
                            export default AdmissionProcedure